import React                       from 'react';
import { Helmet }                  from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import { request, gql }            from 'graphql-request';

import {
  propertiesFields,
  propertyFields,
  propertiesMetaFields,
  endpoints,
} from '../config';

export const IApiContext = React.createContext({});

export const IApiProvider = ({ children }) => {
  const { directusProject } = useStaticQuery(query);
  const graphqlEndpoint = endpoints.api + '/graphql';
  const domain = process.env.GATSBY_SHOP_PROJECT ? process.env.GATSBY_SHOP_PROJECT : directusProject.display_name;

  const fetchProperties = async (name, args) => {

    let { status, limit, types } = args;

    try {
      const query = gql`
          query Property ($domain: String!, $status: String, $limit: Int!, $types: [String]) {
              property(filter: {
                  status: {
                      _eq: $status
                  }
                  type: {
                      _in: $types
                  }
                  project: {
                      domain: {
                          _eq: $domain
                      }
                  }
              }
                  limit: $limit
              ) {
                  id
                  display_name
                  type
                  tags
                  living_space_qm
                  rooms
                  purchase_price_brutto
                  cold_rent_brutto
                  extra_cost_brutto
                  heating_cost_brutto
                  category {
                      display_name
                  }
                  images {
                      directus_files_id {
                          id
                      }
                  }
              }
          }
      `;

      return await request(graphqlEndpoint, query, { domain, status, limit, types });

    } catch (e) {
      throw new Error(e)
    }
  }

  const fetchProperty = async (name, args, page) => {

    const { id } = args;

    try {
      const query = gql`
          query Property ($id: ID!) {
              property_by_id(id: $id) {
                  id
                  internal_id
                  display_name
                  description
                  type
                  tags
                  living_space_qm
                  land_space_qm
                  usable_space_qm
                  rooms
                  purchase_price_brutto
                  cold_rent_brutto
                  extra_cost_brutto
                  heating_cost_brutto
                  deposit
                  courtage
                  availability
                  condition
                  quality
                  construction_year
                  renovation_year
                  pdf_attachments {
                      directus_files_id {
                          filename_disk
                          filename_download
                      }
                  }
                  custom_details {
                      key {
                          display_name
                      }
                      value
                  }
                  city
                  zip
                  street
                  country
                  category {
                      display_name
                  }
                  images {
                      directus_files_id {
                          id
                      }
                  }
              }
          }
      `;

      const property = await request(graphqlEndpoint, query, { id });
      return property.property_by_id;

    } catch (e) {
      throw new Error(e)
    }
  }


  const createRequest = async (request) => {
    let reqReq;
    const body = {
      project: domain,
      ...request
    }
    try {
      reqReq = await fetch(`${endpoints.orderApi}/ecom/request`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });
    } catch (e) {
      console.log(e);
      return {
        error: 'Could not post request'
      }
    }

    const res = await reqReq.json();

    if (res.status === 'ok') {
      return res;
    } else {
      return {
        error: 'Could not post request'
      }
    }

  }


  return <IApiContext.Provider value={{
    fetchProperties,
    fetchProperty,
  }}>
    <Helmet>
      <link rel="preconnect" href={process.env.GATSBY_IMMO_API_ENDPOINT}/>
    </Helmet>
    {children}
  </IApiContext.Provider>
}

const query = graphql`
    query {
        directusProject {
            display_name
            translations {
                seo_title
                language
            }
        }
    }
`;