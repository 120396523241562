export const endpoints = {
  api: process.env.GATSBY_IMMO_API_ENDPOINT,
}

export const defaultQueryConfig = {
  defaultConfig: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
}

export const settingsFields = [

];

export const propertiesFields = [
  'id',
  'display_name'
]

export const propertiesMetaFields = [
  'result_count',
  'filter_count',
  'limit',
  'offset',
  'page',
  'page_count',
  'links.*',
]

export const propertyFields = [
  'id',
]