import React                                   from 'react';
import { ReactQueryDevtools }                  from 'react-query-devtools';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';

import { defaultQueryConfig } from '../config';
import { IApiProvider }       from '../context/IApi';

const queryCache = new QueryCache(defaultQueryConfig);

const Root = ({ children }) => {
  return (
    <IApiProvider>
      <ReactQueryCacheProvider queryCache={queryCache}>
        <>
          <ReactQueryDevtools/>
          {children}
        </>
      </ReactQueryCacheProvider>
    </IApiProvider>
  )
};

export default Root;